body {
  margin: 0;
  font-family: Georgia, 'Times New Roman', Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121213;
}

#message {
  text-align: center;
  color: aliceblue;
}
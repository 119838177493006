/* Global Styles & Colors */
:root {
  --green: #6aaa64;
  --darkendGreen: #538d4e;
  --yellow: #c9b458;
  --darkendYellow: #b59f3b;
  --lightGray: #d8d8d8;
  --gray: #86888a;
  --darkGray: #939598;
  --white: #fff;
  --black: #212121;
  /* Colorblind colors */
  --orange: #f5793a;
  --blue: #85c0f9;
  font-family: 'Clear Sans', 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  --header-height: 50px;
  --keyboard-height: 200px;
  --game-max-width: 500px;

}

/* Dark Theme Colors */
.nightmode {
  --color-tone-1: #ffffff;
  --color-tone-2: #818384;
  --color-tone-3: #565758;
  --color-tone-4: #3a3a3c;
  --color-tone-5: #272729;
  --color-tone-6: #1a1a1b;
  --color-tone-7: #121213;
  --color-nav-hover: #2f2f31;
  --opacity-50: rgba(0, 0, 0, 0.5);
}

/* Constant colors and colors derived from theme */
:root, .nightmode {
  --color-background: var(--color-tone-7);
}
:root {
  --color-present: var(--yellow);
  --color-correct: var(--green);
  --color-absent: var(--color-tone-2);
  --tile-text-color: var(--color-tone-7);
  --key-text-color: var(--color-tone-1);
  --key-evaluated-text-color: var(--color-tone-7);
  --key-bg: var(--color-tone-4);
  --key-bg-present: var(--color-present);
  --key-bg-correct: var(--color-correct);
  --key-bg-absent: var(--color-absent);
  --modal-content-bg: var(--color-tone-7);
}
.nightmode {
  --color-present: var(--darkendYellow);
  --color-correct: var(--darkendGreen);
  --color-absent: var(--color-tone-4);
  --tile-text-color: var(--color-tone-1);
  --key-text-color: var(--color-tone-1);
  --key-evaluated-text-color: var(--color-tone-1);
  --key-bg: var(--color-tone-2);
  --key-bg-present: var(--color-present);
  --key-bg-correct: var(--color-correct);
  --key-bg-absent: var(--color-absent);
  --modal-content-bg: var(--color-tone-7);
}
.colorblind {
  --color-correct: var(--orange);
  --color-present: var(--blue);
  --tile-text-color: var(--white);
  --key-bg-present: var(--color-present);
  --key-bg-correct: var(--color-correct);
  --key-bg-absent: var(--color-absent);
}

html {
  height: 100vh;
}

.App {
  text-align: center;
  color: #ffffff;
  user-select: none;
  -webkit-user-select: none; /*Safari*/
  -moz-user-select: none; /*Firefox*/
}

.keyboard-container {
  position: absolute;
  margin: 2vw;
  bottom: 0;
}

.keyboard-key, .keyboard-special-key, .keyboard-bug-key {
  display: inline-block;
  font-size: 8vw;
  border-radius: 15px;
  background-color: #3c3e3f;
  cursor: pointer;
  margin: 0.3vw;
}

.keyboard-key {
  width:9vw;
  height:10vw;
}

.keyboard-refresh-key {
  position: absolute;
  width:9vw;
  height:9vw;
  font-size: 6vw;
  border-radius: 15px;
  background-color: #3c3e3f;
  cursor: pointer;
}

.keyboard-bug-key {
  position: absolute;
  width:9vw;
  height:9vw;
  font-size: 6vw;
  right: 0;
  border-radius: 15px;
  background-color: #3c3e3f;
  cursor: pointer;
}

.keyboard-bug-key img {
  padding-top: 15%;
  height: 70%;
}

@media screen and (orientation:portrait) {
  .tile-container {
    max-height: 65vh;
  }
  .keyboard-container {
    max-height: 35vh;
  }
}

@media screen and (orientation:landscape) {
  .tile-container {
    max-height: 35vh;
  }
  .keyboard-container {
    max-height: 55vh;
  }
}

.tile-container {
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 2vw;
}

.tile-row-guess {
  display: table-row;
}

.tile {
  font-size: 20vw;
  width: 20vw;
  height: 20vw;
  display: table-cell;
  vertical-align: middle;
  border: 2px solid white;
  border-radius: 1vw;
}

.absent {
  background-color: var(--key-bg-absent);
}

.present {
  background-color: var(--key-bg-present);
}

.correct {
  background-color: var(--key-bg-correct);
}